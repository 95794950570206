var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","align":"stretch"}},[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$lang("appAdmin.networksManagement.title"))+" ")]),_c('v-data-table',{class:{ 'row-pointer': _vm.hasEditAccess },attrs:{"headers":_vm.headers,"items":_vm.networks,"search":_vm.search,"loading":_vm.isLoading,"expanded":_vm.expanded,"show-expand":"","single-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.handleClickByTableRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$lang('components.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","fab":"","outlined":"","exact":"","to":{
                      name: 'historicalRevenueNetwork',
                      params: {
                        id: _vm.applicationId,
                      },
                    }}},on),[_c('v-icon',[_vm._v("mdi-weather-cloudy-clock")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$lang( "appAdmin.networksManagement.historicalRevenueNetwork.title" ))+" ")])]),(_vm.hasCreateAccess)?_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary","small":"","fab":"","to":{
                  name: 'networks-management_new',
                  params: {
                    id: _vm.applicationId,
                  },
                }}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],1)]},proxy:true},{key:"item.lastExecutionStatus",fn:function(ref){
                var item = ref.item;
return [(item.lastExecutionStatus)?_c('v-chip',{staticClass:"ml-1",attrs:{"color":_vm.getStatisticsStatusColor(item),"small":""}},[_vm._v(" "+_vm._s(item.lastExecutionStatus.slice(0, 1) + item.lastExecutionStatus.slice(1).toLowerCase())+" ")]):_vm._e()]}},{key:"item.lastExecutionEndDate",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastExecutionEndDate ? _vm.DateUtil.formatDateTime(item.lastExecutionEndDate) : "")+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(_vm.hasCreateAccess)?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.routeToCopyNetwork(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1):_vm._e(),(_vm.hasDeleteAccess)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleItemDeleting(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-0 rounded-0",style:({ background: _vm.dark ? '#424242' : '#f5f5f5' }),attrs:{"headers":_vm.expandedHeaders,"items":item.networkIntegrations,"tile":"","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.handleClickByExpandedRow(item)}}},[_c('td',{staticStyle:{"min-width":"56px"}}),_c('td',{staticStyle:{"width":"50%"}},[_vm._v(" "+_vm._s(item.networkType)+" ")]),_c('td',{staticStyle:{"width":"30%"}},[(item.lastExecutionStatus)?_c('v-chip',{staticClass:"ml-1",attrs:{"color":_vm.getStatisticsStatusColor(item),"small":""}},[_vm._v(" "+_vm._s(item.lastExecutionStatus.slice(0, 1) + item.lastExecutionStatus.slice(1).toLowerCase())+" ")]):_vm._e()],1),_c('td',{staticStyle:{"width":"10%"}},[_vm._v(" "+_vm._s(item.lastExecutionEndDate ? _vm.DateUtil.formatDateTime(item.lastExecutionEndDate) : "")+" ")]),_c('td',{staticStyle:{"width":"10%"}})])]}}],null,true)})],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }