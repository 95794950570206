
















































































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import NetworkResponseModel from "@/settings/models/networks-management/NetworkResponseModel";
import { StatisticExecutionStatus } from "@/settings/models/networks-management/AccountGroupStatisticModel";
import { NetworkIntegrationShortModel } from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import DateUtil from "@/shared/utils/DateUtil";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component
export default class NetworksView extends mixins(AppSectionAccessMixin) {
  readonly headers = [
    { text: "", value: "data-table-expand" },
    {
      text: this.$lang("appAdmin.networksManagement.tableHeaders.networkName"),
      align: "start",
      value: "networkName",
      width: "50%",
    },
    {
      text: this.$lang(
        "appAdmin.networksManagement.tableHeaders.lastExecutionStatus"
      ),
      align: "start",
      value: "lastExecutionStatus",
      width: "30%",
    },
    {
      text: this.$lang(
        "appAdmin.networksManagement.tableHeaders.lastExecutionEndDate"
      ),
      align: "start",
      value: "lastExecutionEndDate",
      width: "10%",
    },
    {
      text: this.$lang("appAdmin.networksManagement.actions"),
      align: "end",
      value: "actions",
      sortable: false,
      width: "10%",
    },
  ];
  readonly expandedHeaders = [
    {
      text: "",
      value: "",
    },
    {
      text: "",
      align: "start",
      value: "networkType",
    },
    {
      text: "",
      align: "start",
      value: "lastExecutionStatus",
    },
    {
      text: "",
      align: "start",
      value: "lastExecutionEndDate",
    },
    {
      text: "",
      value: "",
    },
  ];
  readonly DateUtil = DateUtil;
  expanded = [];
  search = "";

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get isLoading(): boolean {
    return this.$store.state.networksManagementStore.isLoading;
  }

  get networks(): Array<NetworkResponseModel> {
    return this.$store.state.networksManagementStore.networks;
  }

  getStatisticsStatusColor(item: NetworkResponseModel): string {
    return {
      [StatisticExecutionStatus.FAILED]: "error",
      [StatisticExecutionStatus.SUCCESS]: "success",
    }[item.lastExecutionStatus];
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("appAdmin.networksManagement.title")
    );
  }

  mounted() {
    this.$store.dispatch("loadNetworks");
  }

  handleClickByTableRow(item: NetworkResponseModel) {
    if (!this.hasEditAccess) {
      return;
    }

    this.$router.push({
      name: "networks-management_view",
      params: {
        id: this.applicationId,
        networkId: String(item.id),
      },
    });
  }

  handleClickByExpandedRow(item: NetworkIntegrationShortModel) {
    this.$router.push({
      name: "networkIntegration",
      params: {
        id: this.applicationId,
        networkId: String((this.expanded[0] as NetworkResponseModel).id),
        networkIntegrationId: String(item.id),
      },
    });
  }

  routeToCopyNetwork(item: NetworkResponseModel) {
    this.$router.push({
      name: "networks-management_new",
      params: {
        id: this.applicationId,
      },
      query: {
        networkId: String(item.id),
        networkName: item.networkName,
      },
    });
  }

  handleItemDeleting(item: NetworkResponseModel) {
    this.$store.dispatch("deleteNetwork", item.id);
  }
}
